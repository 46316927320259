import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const defaultPath = { name: 'SearchByClass' }

Vue.use(VueRouter)
const routes = [
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/pages/PageSettings.vue'),
    beforeEnter: (to, from, next) => {
      if (
        store.state.user.originator === 'system' ||
        !store.state.user.endpoint
      )
        next()
      else next(defaultPath)
    },
  },
  {
    path: '/',
    name: 'Main',
    redirect: defaultPath,
    exact: true,
  },
  {
    path: '/auto-ml-results',
    name: 'AutoMLResults',
    component: () => import('@/pages/PageAutoMLResults.vue'),
  },

  {
    path: '/redirect/:endpoint/:code',
    name: 'Redirect',
    component: () => import('@/pages/PageRedirect.vue'),
  },
  {
    path: '/logos',
    name: 'Logos',
    component: () => import('@/pages/PageLogos.vue'),
  },
  {
    path: '/duplicates',
    name: 'Duplicates',
    component: () => import('@/pages/PageDuplicates.vue'),
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/pages/PageRules.vue'),
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: () => import('@/pages/PageGlossary.vue'),
    children: [
      {
        path: '/glossary/:id',
        component: () => import('@/pages/PageGlossary.vue'),
      },
    ],
  },
  {
    path: '/search-by-class',
    name: 'SearchByClass',
    component: () => import('@/pages/PageSearchByClass.vue'),
    children: [
      {
        path: '/search-by-class/:code',
        component: () => import('@/pages/PageSearchByClass.vue'),
      },
    ],
  },
  {
    path: '/object/:code',
    component: () => import('@/pages/PageObjectView.vue'),
    name: 'Object',
  },
  {
    path: '/reference/:code',
    component: () => import('@/pages/PageReferenceView.vue'),
    name: 'Reference',
  },
  {
    path: '/class/:code',
    component: () => import('@/pages/PageClassView.vue'),
    name: 'Class',
  },
  {
    path: '/around',
    component: () => import('@/pages/PageAround.vue'),
    name: 'Around',
  },
  {
    path: '/quality',
    component: () => import('@/pages/PageQuality.vue'),
    name: 'Quality',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
})

export default router
