import axios from 'axios'
import store from '@/store'
import { socket } from '@/utils/socket'

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL.replace(/\/*$/, '/') + 'logic',
  withCredentials: true,
})
api.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
    },
  }
})
api.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    const xerror = response.headers['x-error']
    if (xerror)
      store.commit('toast', [
        decodeURIComponent(response.headers['operation-name']) +
          '\n' +
          decodeURIComponent(xerror),
        'warn',
      ])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      if (store.state.user.isLoggedIn) store.dispatch('Logout')
    } else {
      let msg =
        error.response.data?.message ||
        error.response.data?.Status ||
        error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios (rules): ${error.message}. ${error.response.data}`)
  },
)

const state = {
  groups: {
    flat: [],
    tree: [],
  },
  active: null,
  endpointsForGroup: [],
}

const mutations = {
  SetGroups(state, payload) {
    state.groups.flat = payload.flatGroups
    state.groups.tree = payload.groups

    state.allRules = payload.flatGroups.reduce((res, group) => {
      if (!group.rules?.length) return res
      return [
        ...res,
        ...group.rules.map(rule => {
          let endpoints = group.endpoints
          let parent = group.parent
          while (!endpoints?.length && parent != 0) {
            const gParent = payload.flatGroups.find(e => e.id == parent)
            endpoints = gParent?.endpoints
            parent = gParent?.parent
          }
          return { ...rule, endpoints }
        }),
      ]
    }, [])
  },
  ToggleActiveGroup(state, payload) {
    state.active = null
    state.endpointsForGroup.length = 0
    this.commit('SetPage', 1)
    this.dispatch('LoadEndpointsForGroup', payload.id)
    state.active = payload
  },
  SetEndpointsForGroup(state, payload) {
    state.endpointsForGroup = payload
  },
}

const actions = {
  async CreateGroup(context, newGroup) {
    await api.post('groups', newGroup)
    await context.dispatch('LoadGroups', true)
  },
  async DeleteGroup(context, id) {
    await api.delete(`groups/${id}`)
    context.commit('SetPage', 0)
    context.dispatch('LoadGroups')
  },
  async LoadEndpointsForGroup(context, payload) {
    context.commit(
      'SetEndpointsForGroup',
      await api.get(`groups/${payload}/endpoints`),
    )
  },
  async LoadGroups(context, payload) {
    let data
    if (payload) {
      if (typeof payload === 'boolean' && payload) {
        context.commit('reset')
      } else if (typeof payload === 'object') {
        data = payload
      }
    }
    if (!data) {
      data = await api.get('groups')
    }
    const groups = data.groups
    const flatGroups = []
    const Flat = item => {
      flatGroups.push(item)
      if (item.groups) for (const group of item.groups) Flat(group)
      delete item.groups
    }
    for (const group of structuredClone(groups)) Flat(group)

    context.commit('SetGroups', {
      flatGroups,
      groups,
    })
  },
  async SaveGroup(context, payload) {
    await api.patch('groups', payload)
    // context.commit('SetPage', 0)
    await context.dispatch('LoadGroups')
    context.commit(
      'ToggleActiveGroup',
      context.state.groups.flat.find(e => e.id === payload.group.id),
    )
  }
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
