function updateElementHeight(el) {
  el.style.height = '0'
  el.style.height = `${el.scrollHeight + 2}px`
}

export default {
  inserted: el => {
    setTimeout(() => {
      updateElementHeight(el)
    })
    window.addEventListener('resize', () => updateElementHeight(el))
  },
  update: el => {
    updateElementHeight(el)
  },
  unbind: el => {
    window.removeEventListener('resize', () => updateElementHeight(el))
  },
}
