import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'
import router from '@/router'
import glossary from '@/store/modules/glossary'
import endpoints from '@/store/modules/endpoints'
import language from '@/store/modules/language'
import searchByClass from '@/store/modules/searchByClass'
import rules from '@/store/modules/rules'
import ruleBuilder from '@/store/modules/rule_builder'
import rulesGroups from '@/store/modules/rule_groups'
import ruleConditions from '@/store/modules/rule_conditions'
import LicenseToast from '@/components/LicenseToast'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    toasts: [],
    user: {},
    isLoading: 0,
    appRefreshKey: 0,
  },
  mutations: {
    toast(state, payload) {
      state.toasts.push(payload)
    },
    clearToasts(state) {
      state.toasts = []
    },
    Loading(state, payload) {
      if (payload == 'disable') state.isLoading--
      else if (payload == null) state.isLoading = 0
      else {
        if (payload) state.isLoading++
        else state.isLoading = Math.max(state.isLoading - 1, 0)
      }
    },
    SetUser(state, payload) {
      if (payload.originator) {
        state.user = {
          isLoggedIn: true,
          username: payload.name || '???',
          originator: payload.originator,
          hasMappingKC: payload.hasMappingKC,
          endpoint: payload.endpoints?.find(e => e.Code == payload.endpoint)
            .Name,
          endpointId: payload.endpoints?.find(e => e.Code == payload.endpoint)
            .Code,
          access: payload.access,
        }
        localStorage.setItem('endpoint', state.user.endpoint)
      } else {
        state.user = { isLoggedIn: false }
      }
    },
    SetEndpoint(state, payload) {
      state.user.endpointId = payload
      state.user.endpoint = state.endpoints.endpoints.find(
        e => e.Code == payload,
      )?.Name
    },
    Refresh(state, lazy) {
      state.appRefreshKey = state.appRefreshKey + (lazy ? 2 : 1)
    },
    resetUser(state) {
      state.user = {}
    },
  },
  actions: {
    async GetLicense(context) {
      try {
        let response = await api.get('/check-license')
        if (response.status !== 'success') {
          const content = {
            component: LicenseToast,
            props: {
              text: response.message,
            },
          }
          this._vm.$toast(content, {
            type: response.status,
            timeout: false,
            position: 'top-center',
            icon: false,
            draggablePercent: 2,
          })
        }
      } catch (e) {
        console.error(e)
      }
      setTimeout(() => context.dispatch('GetLicense'), 60 * 60 * 1_000)
    },
    async Auth(context) {
      const data = await apiLazy.get('auth')
      context.commit('SetEndpoints', data.endpoints)
      context.commit('SetUser', data)
    },
    async Logout(context, lazy) {
      context.commit('resetUser')
      if (!lazy) {
        await api.post('logout')
      }
      context.commit('Refresh', true)
      context.dispatch('login')
    },
    login() {
      const front = encodeURIComponent(window.location.origin)
      const href = encodeURIComponent(window.location.href)
      const back = import.meta.env.VITE_SERVER_URL.replace(/(^\/)|(\/$)/g, '')
      window.location =
        window.location.origin +
        '/' +
        back +
        '/oauth/login' +
        '?front=' +
        front +
        '&href=' +
        href +
        '&back=' +
        encodeURIComponent(back)
    },
  },
  getters: {},
  modules: {
    glossary,
    searchByClass,
    ruleBuilder,
    rulesGroups,
    rules,
    ruleConditions,
    endpoints,
    language,
  },
})
