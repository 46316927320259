import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'

const state = {
  endpoints: [],
}

const mutations = {
  SetEndpoints: (state, payload) => {
    state.endpoints = payload
  },
}

const actions = {
  async SetEndpoint(context, code) {
    await api.patch(`endpoint/${code}`)
    sessionStorage.clear()
    context.commit('SetEndpoint', code)
    localStorage.setItem('endpoint', code)
    context.commit('Refresh')
  },
}

const getters = {
  endpoints: state => state.endpoints,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
