import { io } from 'socket.io-client'

export const socket = io(
  /^http/.test(import.meta.env.VITE_SOCKET_URL)
    ? import.meta.env.VITE_SOCKET_URL
    : (window.location.origin,
      {
        path: import.meta.env.VITE_SOCKET_URL + '/socket.io',
        transports: ['websocket'],
        upgrade: false,
      }),
)

socket.on('message', data => {
  console.info(data)
})

export const awaitConnect = () =>
  new Promise(async resolve => {
    while (!socket.connected) {
      await new Promise(resolve => {
        setTimeout(() => resolve(), 50)
      })
    }
    resolve()
  })

export const emit = async (...args) => {
  await awaitConnect()
  socket.emit(...args)
}
