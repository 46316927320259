import store from '@/store'
import axios from 'axios'
import { socket } from '@/utils/socket'
import { v4 as uuid } from 'uuid'

const client = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  withCredentials: true,
  timeout: 1000 * 60 * 20,
})

const noLoadingUrls = [/referenceTargets/, /around\/\d$/, /check-license/, /ml/]
client.interceptors.request.use(config => {
  if (!noLoadingUrls.some(url => url.test(config.url))) {
    store.commit('Loading', true)
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
      'X-Request-Id': uuid(),
    },
  }
})

client.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      store.dispatch('Logout', /auth$/.test(error.response.config?.url))
    } else {
      if (
        !(
          error.response.status === 504 &&
          error.request.responseURL.includes('around/')
        )
      ) {
        let msg = error.response.data?.message || error.response.data
        const operationName = error.response.headers['operation-name']
        if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
        store.commit('toast', [msg, 'error'])
      }
    }
    throw new Error(`Axios: ${error.message}. ${error.response.data}`)
  },
)

const pending = new Map()
client._get = client.get
client.get = async (...args) => {
  const stringify = JSON.stringify(args)
  if (pending.has(stringify)) {
    await new Promise(resolve => {
      const wait = () => {
        if (pending.get(stringify) !== null) resolve()
        else setTimeout(() => wait())
      }
      wait()
    })
    return pending.get(stringify)
  } else {
    pending.set(stringify, null)
    const data = await client._get(...args)
    pending.set(stringify, data)
    setTimeout(() => {
      pending.delete(stringify)
    }, 500)
    return data
  }
}

export default client
