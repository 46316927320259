import apiLazy from '@/utils/api-lazy'

const state = {
  classes: [],
  classesTree: [],
  classesWithPrefix: [],
  modalActiveCode: '',
  root: '',
}

const mutations = {
  SetLastSearch(state, payload) {
    state.lastSearch = payload
  },
  SetClasses(state, payload) {
    state.classes = payload.sorted
    state.classesTree = payload.tree
    state.classesWithPrefix = payload.withPrefix
    state.root = payload.root
    state.prefix = payload.prefix
  },
  ToggleActiveClass(state, payload) {
    state.modalActiveCode = state.modalActiveCode === payload ? '' : payload
  },
  ResetModalActiveClass(state) {
    state.modalActiveCode = ''
  },
}

const actions = {
  async LoadClasses(context) {
    try {
      const data = await apiLazy.get('modeltree')
      context.commit('SetClasses', data)
    } catch {
      context.commit('SetClasses', { sorted: [], tree: [] })
    }
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
