import store from '@/store'
import en from '@/locales/en.json'

const locales = {
  en: en,
}

function StringLocalize(string, required) {
  const language = store.state.language.language
  if (
    locales[language]?.[string] === undefined &&
    language !== 'ru' &&
    required
  )
    console.warn(`Отсутсвует перевод "${string}" (${language})`)
  return locales[language]?.[string] || string
}

function ArrayLocalize(array) {
  return array.map(localize)
}

function ObjectLocalize(object) {
  for (const [key, value] of Object.entries(object)) {
    object[key] = localize(value)
  }
  return object
}

function localize(
  value,
  required = true /*Отображение уведомления при отсутствии перевода*/,
) {
  if (typeof value === 'string' && isNaN(value) && /[а-яё]/i.test(value))
    return StringLocalize(value, required)
  else if (Array.isArray(value)) return ArrayLocalize(value)
  else if (typeof value === 'object') return ObjectLocalize(value)
  else return value
}

export default localize
