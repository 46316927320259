import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import './polyfills'

import globalMixin from '@/mixins/global.mixin.js'
Vue.mixin(globalMixin)
import 'leaflet/dist/leaflet.css'

import draggable from '@/mixins/vue-draggable.js'
Vue.use(draggable)

import localize from '@/filters/localize'
Vue.filter('localize', localize)

import delprefix from '@/filters/delprefix'
Vue.filter('delprefix', delprefix)

import frag from 'vue-frag'
Vue.directive('frag', frag)

import textareaDynamicHeight from '@/directives/textareaDynamicHeight'
Vue.directive('dynamic-height', textareaDynamicHeight)

import VuePikaday from '@/assets/modules/vue-pikaday/dist/vue-pikaday.js'
import '@/assets/modules/vue-pikaday/dist/vue-pikaday.min.css'
Vue.component('vue-pikaday', VuePikaday)

import '@/assets/styles/vue-select/vue-select.scss'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

import vTitle from 'vuejs-title'
Vue.use(vTitle)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
Vue.use(Toast, {
  position: 'bottom-right',
  timeout: 100000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
})

Vue.config.productionTip = false

import '@/utils/socket'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
