/* eslint-disable */
/*
	Drag lists items.

	Ussage:

<ul v-drop="list">
	<li v-drag="i" v-for="( item, i ) in list">{{item}}</li>
</ul>

<ul v-drop="list" dragClass="classForDropping">
	<li v-drag="i" v-for="( item, i ) in list">{{item}}</li>
</ul>

class name for dragging element is "dragging"
class name name for dropping element is "dropping"
*/

const confirmTarget = (el, dropEl) => {
  if (el && dropEl.contains(el)) {
    for (el; el && el !== dropEl; el = el.parentNode) {
      if (el.draggable && el.classList) {
        return el
      }
    }
  }
}

export default function (Vue) {
  Vue.directive('drag', {
    bind(element, binding, vnode) {
      element.draggable = true

      element.indexValue = binding.value

      element.ondragstart = event => {
        element.classList.add('dragging')
        event.dataTransfer.setData('text', element.indexValue)
      }
      element.ondragend = event => {
        element.classList.remove('dragging')
      }
      element.ondrag = function (event) {}
    },
    update: function (element, binding, vnode) {
      element.indexValue = binding.value
    },
    unbind: function () {},
  })
  Vue.directive('drop', {
    twoWay: true,
    bind(element, binding, vnode) {
      let sel = null

      element.ondragenter = event => {
        let target = confirmTarget(event.target, element)
        if (sel !== target) {
          if (sel && sel.classList) sel.classList.remove('dropping')
          sel = target
          if (sel && sel.classList) sel.classList.add('dropping')
        }
      }
      element.ondragleave = event => {
        event.preventDefault()
      }
      element.ondragover = event => {
        event.preventDefault()
      }
      element.ondrop = event => {
        event.preventDefault()
        event.stopPropagation()

        if (sel && sel.classList) {
          sel.classList.remove('dropping')
          const v = vnode.context[binding.expression] //binding.value;

          if (Array.isArray(v)) {
            const len = v.length
            const fromIndex = parseInt(event.dataTransfer.getData('text'), 10)
            if (!isNaN(fromIndex) && fromIndex < len) {
              const toIndex = parseInt(sel.indexValue, 10)
              if (!isNaN(toIndex) && toIndex < len && fromIndex != toIndex) {
                v.splice(
                  toIndex +
                    (fromIndex == toIndex - 1) +
                    (fromIndex < toIndex && fromIndex ? -1 : 0),
                  0,
                  v.splice(fromIndex, 1)[0],
                )
                vnode.context[binding.expression] = v
              }
            }
          } else {
            const fromKey = event.dataTransfer.getData('text')
            const toKey = sel.indexValue

            if (fromKey !== toKey) {
              let fromIndex = 0
              let toIndex = 0
              let i = 0
              for (let key in v) {
                if (v.hasOwnProperty(key)) {
                  if (key === fromKey) fromIndex = i
                  if (key === toKey) toIndex = i
                  i++
                }
              }

              const nv = {}
              for (let key in v) {
                if (v.hasOwnProperty(key)) {
                  if (key === fromKey) continue

                  const c = fromIndex == toIndex - 1

                  if (!c && key === toKey) nv[fromKey] = v[fromKey]
                  nv[key] = v[key]
                  if (c && key === toKey) nv[fromKey] = v[fromKey]
                }
              }

              vnode.context[binding.expression] = nv
            }
          }

          sel = null
        }
      }
    },
    update: function (element, binding, vnode) {},
    unbind: function () {},
  })
}
