<template>
  <div class="check-license">
    <h1>Проверка лицензии</h1>
    <h2>
      {{ text }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'LicenseToast',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>
.check-license {
  display: grid;
  gap: 10px;
  padding: 10px;
}
</style>
