import axios from 'axios'
import store from '@/store'
import { socket } from '@/utils/socket'
import { downloadBlob } from '../../utils/download'

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL.replace(/\/*$/, '/') + 'logic',
  withCredentials: true,
})
api.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
    },
  }
})
api.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    const xerror = response.headers['x-error']
    if (xerror)
      store.commit('toast', [
        decodeURIComponent(response.headers['operation-name']) +
          '\n' +
          decodeURIComponent(xerror),
        'warn',
      ])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      if (store.state.user.isLoggedIn) store.dispatch('Logout')
    } else {
      let msg =
        error.response.data?.message ||
        error.response.data?.Status ||
        error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios (rules): ${error.message}. ${error.response.data}`)
  },
)

const newState = () => ({
  allRules: [],
  // vars: [],
  page: 0, // 0 - ничего, 1 - изменение группы, 2 - изменение правила, 3 - создание правила
})

const state = newState()

const sortKeys = v => {
  const { id, name, active } = v
  return { id, name, active, ...v }
}

const mutations = {
  reset(state) {
    Object.assign(state, newState())
  },
  SetPage(state, page) {
    state.page = page
  },
  // SetVar(state, payload) {
  //   if (payload.var === '$this' && state.vars.length) return
  //   state.vars = state.vars.filter(e => e.var != payload.var)
  //   state.vars.push(payload)
  // },
  // ClearVars(state){
  //   state.vars = []
  // }
}

const InsertRules = (tree, rules, id) => {
  const Insert = (group, rules, id) => {
    if (group.id == id) {
      if (!group.rules) group.rules = []
      group.rules.push(...rules)
    }
    if (group.groups) for (const item of group.groups) Insert(item, rules, id)
  }
  Insert(tree, rules, id)
}

const ReUpdateGroup = context => {
  for (const group of context.state.groups.flat)
    if (group.id == context.state.activeGroup.id) {
      context.commit('ToggleActiveGroup', group)
      break
    }
}

const GetRuleSubtree = (tree, ruleId) => {
  let subTree = structuredClone(
    tree.groups.find(e =>
      new RegExp(`"id":"${ruleId}","name":"([^"]*)","active":`, 'g').test(
        JSON.stringify(e),
      ),
    ),
  )
  let found = false
  const DeleteExtra = group => {
    if (group.rules?.length) {
      group.rules = group.rules.filter(e => e.id == ruleId)
      if (group.rules.length === 1) {
        delete group.groups
        found = true
        if (ruleId == 'tempID') delete group.rules[0].id
      } else delete group.rules
    }
    if (!found && group.groups?.length) {
      group.groups = [
        group.groups.find(e =>
          new RegExp(`"id":"${ruleId}","name":"([^"]*)","active":`, 'g').test(
            JSON.stringify(e),
          ),
        ),
      ]
      if (group.groups[0]) DeleteExtra(group.groups[0])
    }
  }
  DeleteExtra(subTree)
  return { groups: [subTree] }
}

const actions = {

  async openRule(context, id) {
    const group = context.state.groups.flat.find(group =>
      group.rules?.some(rule => rule.id == id),
    )
    if (group) {
      const rule = group.rules.find(e => e.id == id)
      if (rule) {
        context.commit('ToggleActiveGroup', group)
        setTimeout(() => context.commit('ToggleActiveRule', rule), 100)
        await context.dispatch('LoadConditions', rule)
        sessionStorage.setItem('openRule', id)
      }
    }
  },
}

const getters = {
  // vars: state => {
  //   const result = {}
  //   for (const _var of state.vars) result[_var.var] = _var.class
  //   return result
  // },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
