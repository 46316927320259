import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'

const getDefaultLanguage = () => {
  const possible = ['ru', 'en']
  const priority = 'ru'
  const language = (
    localStorage.getItem('language') || window.navigator.language
  ).split('-')[0]
  return possible.find(e => e == language) || priority
}

const state = {
  language: getDefaultLanguage(),
}

const mutations = {
  SetLanguage(state, lang) {
    localStorage.setItem('language', lang)
    state.language = lang
  },
}

const actions = {
  async SetLanguage(context, lang) {
    context.commit('SetLanguage', lang)
    await api.patch('lang/' + lang)
    sessionStorage.clear()
    context.commit('Refresh')
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
