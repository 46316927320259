import api from '@/utils/api'
import apiLazy from '@/utils/api-lazy'

const state = {
  terms: [],
  termsAlphabet: {},
  termsTree: [],
  inGlossaryItemEditor: false,
}

const TermsToAlphabetObj = (terms, word = '') => {
  return terms.reduce((res, e) => {
    const letter = e.label[0].toUpperCase()
    if (e.label.toLocaleLowerCase().indexOf(word.toLocaleLowerCase()) !== -1) {
      if (res[letter] === undefined) res[letter] = []
      res[letter].push(e)
    }
    return res
  }, {})
}

const mutations = {
  SetTerms(state, payload) {
    state.terms = payload.sorted
    state.termsAlphabet = TermsToAlphabetObj(payload.sorted)
    state.termsTree = payload.tree
  },
  ToggleGlossaryItemEditor(state, bool = !state.inGlossaryItemEditor) {
    state.inGlossaryItemEditor = bool
  },
}

const actions = {
  async LoadTerms(context) {
    try {
      const data = await apiLazy.get('glossary/terms')
      context.commit('SetTerms', data)
    } catch {
      context.commit('SetTerms', { sorted: [], tree: [] })
    }
  },
}

const getters = {
  GetAlphabetTerms:
    state =>
    (word = '') => {
      const terms = word.length
        ? TermsToAlphabetObj(state.terms, word)
        : state.termsAlphabet
      const result = []
      for (const [key, value] of Object.entries(terms))
        result.push({ letter: key, terms: value })
      return result
    },
  GetAlphabetLetters:
    state =>
    (word = '') => {
      return Object.keys(
        word.length
          ? TermsToAlphabetObj(state.terms, word)
          : state.termsAlphabet,
      )
    },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
