import moment from 'moment'
import Pikaday from 'pikaday'
import isDate from 'lodash/isDate'

export default {
  name: 'vue-pikaday',
  inheritAttrs: false,
  props: {
    value: {
      default: null,
      required: true,
    },
    options: {
      required: false,
      default() {
        return {}
      },
    },
    autoDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      elAttrs: {
        type: 'text',
      },
      defaultOptions: {
        format: 'D MMM YYYY',
      },
    }
  },
  computed: {
    elementAttributes() {
      return Object.assign({}, this.$attrs, this.elAttrs)
    },
    mergedOptions() {
      return Object.assign({}, this.defaultOptions, this.options)
    },
  },
  render(h) {
    return h(
      'input',
      {
        attrs: this.elementAttributes,
        on: this.$listeners,
        value: this.inputValue(this.value),
      },
      this.$slots.default,
    )
  },
  mounted() {
    this.create()

    this.$watch('value', value => {
      if (!isDate(value)) {
        value = null
      }
      if (!this.visible) {
        this.pikaday.setDate(value, true)
      }
      this.change(value)
    })
  },
  beforeDestroy() {
    this.destroy()
  },
  watch: {
    options: {
      handler() {
        this.reload()
      },
      deep: true,
    },
  },
  methods: {
    create() {
      this.mergedOptions.field = this.$el

      this.bindListener('onSelect', () => this.onSelect())
      this.bindListener('onOpen', () => this.onOpen())
      this.bindListener('onClose', () => this.onClose())

      this.pikaday = new Pikaday(this.mergedOptions)

      let defaultValue = this.value

      if (!this.value && this.autoDefault) {
        defaultValue = moment().toDate()
        this.change(defaultValue)
      }

      this.pikaday.setDate(defaultValue, true)

      if (this.mergedOptions.bound === false) {
        this.hide()
      } else {
        this.visible ? this.show() : this.hide()
      }
    },
    destroy() {
      this.pikaday.destroy()
    },
    reload() {
      this.destroy()
      this.create()
    },
    change(v) {
      if ((v instanceof Date && isFinite(v)) || v === null) {
        this.$emit('input', v)
        this.$emit('input-value', this.inputValue(v))
      }
    },
    inputValue(value) {
      if (!isDate(value)) {
        return null
      }
      const inputValue = moment(value)
      return inputValue.isValid()
        ? inputValue.format(this.mergedOptions.format)
        : null
    },
    onSelect() {
      this.change(this.pikaday.getDate())
    },
    onOpen() {
      this.visible = true
    },
    onClose() {
      if (!isDate(this.value)) {
        this.pikaday.setDate(null, true)
        this.change(null)
      }
      this.visible = false
    },
    show() {
      this.pikaday.show()
    },
    hide() {
      this.pikaday.hide()
    },
    bindListener(listener, cb) {
      if (this.mergedOptions[listener]) {
        const old = this.mergedOptions[listener]
        this.mergedOptions[listener] = (...args) => {
          old(args)
          cb.apply(this)
        }
      } else {
        this.mergedOptions[listener] = cb
      }
    },
  },
}
