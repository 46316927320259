const globalMixin = {
  methods: {
    localize(v) {
      return this.$options.filters.localize(v)
    },
    toast(text, type) {
      type =
        {
          warn: 'warning',
          ok: 'success',
        }[type] || type
      this.$toast[type || 'info'](this.localize(text))
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading > 0
    },
    root() {
      return this.$store.state.searchByClass.root
    },
    prefix() {
      return this.$store.state.searchByClass.prefix
    },
  },
}

export default globalMixin
