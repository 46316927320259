import axios from 'axios'
import store from '@/store'
import { socket } from '@/utils/socket'
import { v4 as uuid } from 'uuid'

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL.replace(/\/*$/, '/') + 'logic',
  withCredentials: true,
})
api.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
    },
  }
})
api.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    const xerror = response.headers['x-error']
    if (xerror)
      store.commit('toast', [
        decodeURIComponent(response.headers['operation-name']) +
          '\n' +
          decodeURIComponent(xerror),
        'warn',
      ])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      if (store.state.user.isLoggedIn) store.dispatch('Logout')
    } else {
      let msg =
        error.response.data?.message ||
        error.response.data?.Status ||
        error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios (rules): ${error.message}. ${error.response.data}`)
  },
)

export const newId = () => `temp_${uuid().replace(/-/g, '')}`
export const newCondition = () => ({
  id: newId(),
  operation: 'and',
  object: '',
  object_type: 'val',
  relation: '',
  value: '',
  value_type: 'val',
  object_class: '',
  relation_no: '0',
  delete_existed: '0',
})
const toOrder = (group, order) => {
  if (!order) order = []
  // const order = []
  if (group.groups) {
    for (let entry of group.groups) {
      const orderItem = {
        id: entry.id,
        isGroup: true,
        groups: toOrder(entry),
      }
      order.push(orderItem)
    }
  }
  if (group.conditions) {
    for (let entry of group.conditions) {
      const orderItem = {
        id: entry.id,
        isGroup: false,
      }
      order.push(orderItem)
    }
  }
  return order
}
const blocks = state => ({
  conditions: state.conditions,
  output: state.output,
})
//Поднимает и удаляет пустые группы и условия
const DeleteEmpty = (arr, state) => {
  // return
  if (!arr) return

  const Delete = arr => {
    if (arr.groups?.length) {
      for (const item of arr.groups) {
        if (!item.groups?.length && !item.conditions?.length)
          arr.groups = arr.groups.filter(e => e.id != item.id)
        Delete(item)

        // if (item.conditions?.length == 1 && !item.groups?.length) {
        //   arr.conditions.unshift(item.conditions[0])
        //   arr.groups = arr.groups.filter(e => e.id != item.id)
        // }
      }
      if (arr.groups.length == 1 && !arr.conditions?.length) {
        if (!arr.groups[0].groups) arr.groups[0].groups = []
        Object.assign(arr, arr.groups[0])
      }
    }
  }

  for (
    let i = 0;
    i <= (JSON.stringify(arr).match(/"groups"/g) || []).length;
    i++
  )
    Delete(arr)

  if (!arr.conditions?.length && !arr.groups?.length) {
    arr.conditions = []
    arr.conditions.push(newCondition(state))
  }
}
const ReEnableVars = state => {
  const json =
    JSON.stringify(state.conditions) + '|' + JSON.stringify(state.output)
  for (const item of state.vars) {
    const _var = item.var
    if (_var !== '$this')
      if (
        !(
          new RegExp(`"object":"${_var}"`, 'g').test(json) ||
          new RegExp(`"value":"${_var}"`, 'g').test(json)
        )
      ) {
        state.vars = state.vars.filter(e => e.var !== item.var)
      }
  }
}

const state = {
  conditions: {},
  output: {},
  order: [],
}

const mutations = {
  SetConditions(state, payload) {
    this.commit('ClearVars')
    state.rules = structuredClone(payload)
    state.conditions = payload.find(e => e.num != 0)
    state.output = payload.find(e => e.num == 0)
    DeleteEmpty(state.conditions, state)
    DeleteEmpty(state.output, state)
  },
  BuildOrder(state, payload) {
    if (payload.length === 0) {
      state.order = toOrder(state.conditions)
    } else {
      state.order = toOrder(payload)
    }
  },
  SetOrder(state, payload) {
    state.order = payload
  },
  UpdateCondition(state, payload) {
    const Update = group => {
      if (group.conditions?.length)
        for (const item of group.conditions)
          if (item.id == payload.id) Object.assign(item, payload)

      if (group.groups?.length) for (const item of group.groups) Update(item)
    }
    Update(state.conditions)
    Update(state.output)
    ReEnableVars(state)
  },
}

const actions = {
  async SaveConditions(context, payload) {
    const groupId = context.rootState.rulesGroups.active.id
    const ruleId = context.rootState.rules.active.id

    const exists = {
      conditions: {
        conditions: [],
        groups: [],
      },
      output: {
        conditions: [],
        groups: [],
      },
    }
    const newIds = {
      conditions: {
        conditions: [],
        groups: [],
      },
      output: {
        conditions: [],
        groups: [],
      },
    }
    const forDelete = {
      conditions: [],
      groups: [],
    }
    const forCreateOrUpdate = {
      conditions: [],
      groups: [],
    }

    const CollectGroupsAndConditions = (group, obj) => {
      if (group.groups) {
        for (let gr of group.groups) {
          obj.groups.push(gr.id)
          CollectGroupsAndConditions(gr, obj)
        }
      }
      if (group.conditions) {
        for (let cond of group.conditions) {
          obj.conditions.push(cond.id)
        }
      }
    }
    const CollectToDelete = k => {
      for (let key in exists[k]) {
        for (let id of exists[k][key]) {
          if (newIds[k][key].includes(id)) continue
          forDelete[key].push(id)
        }
      }
    }
    const CollectToCreateOrUpdate = (group, parentId) => {
      const HandleGroup = gr => {
        const g = {
          id: gr.id,
          num: gr.num,
          operation: gr.operation,
          rule: ruleId,
          parent: parentId,
        }
        forCreateOrUpdate.groups.push(g)
      }
      const HandleCondition = condition => {
        const c = {
          ...condition,
          group: group.id,
          rule: ruleId,
        }
        delete c.groups
        delete c.conditions
        forCreateOrUpdate.conditions.push(c)
      }
      if (group.groups) {
        for (let gr of group.groups) {
          HandleGroup(gr)
          CollectToCreateOrUpdate(gr, gr.id)
        }
      }
      if (group.conditions) {
        for (let cond of group.conditions) {
          HandleCondition(cond)
        }
      }
    }

    CollectGroupsAndConditions(context.state.conditions, exists.conditions)
    CollectGroupsAndConditions(payload.conditions, newIds.conditions)
    CollectToDelete('conditions')
    
    if (!context.rootState.rules.active.constraint) {
      CollectGroupsAndConditions(context.state.output, exists.output)
      CollectGroupsAndConditions(payload.output, newIds.output)
      CollectToDelete('output')
      // CollectToCreateOrUpdate(payload.output, payload.output.id)
    }

    let order = JSON.stringify(payload.order)
    delete payload.order
    let data = await api.post(`/groups/${groupId}/rules/${ruleId}`, payload)

    for (let tempId in data) {
      order = order.replace(tempId, data[tempId])
    }
    order = JSON.parse(order)
    await context.dispatch('SaveOrder', order)

    context.dispatch('LoadConditions')
  },
  async LoadConditions(context, payload) {
    const groupId = context.rootState.rulesGroups.active.id
    const ruleId = context.rootState.rules.active.id
    let data = await api.get(`groups/${groupId}/rules/${ruleId}`)
    if (!data.length)
      data = [
        {
          id: newId(context.state),
          num: '0',
          operation: 'and',
          conditions: [newCondition(context.state)],
        },
        {
          id: newId(context.state),
          num: '1',
          operation: 'and',
          conditions: [newCondition(context.state)],
        },
      ]
    else if (data.length === 1)
      data.unshift({
        id: newId(context.state),
        num: '0',
        operation: 'and',
        conditions: [newCondition(context.state)],
      })
    context.commit('SetConditions', data)
    await context.dispatch('LoadOrder')
  },
  async LoadOrder(context) {
    const groupId = context.rootState.rulesGroups.active.id
    const ruleId = context.rootState.rules.active.id
    const data = await api.get(`groups/${groupId}/rules/${ruleId}/order`)
    if (data.length) {
      context.commit('SetOrder', data)
    } else {
      context.commit('BuildOrder', data)
    }
  },
  async SaveOrder(context, payload) {
    const groupId = context.rootState.rulesGroups.active.id
    const ruleId = context.rootState.rules.active.id
    await api.post(`groups/${groupId}/rules/${ruleId}/order`, payload)
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
